if (window.location.pathname.startsWith('/signin-oidc-silent')) {
	import('./auth/userManager').then(({ signinCallback }) => {
		signinCallback('signin-oidc-silent')
	})
} else {
	import('./pendo')
	import('./bootstrap')
}

export {}
